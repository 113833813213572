import React, { useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Banner from './banner.png';
import Logo from './logo.png';

import Footer from './components/Footer';
import Routes from './components/Routes';

import './App.scss';

const App: React.FC = (): JSX.Element => {
  // useEffect(() => {
  //   const functions = getFunctions();
  //   const addMessage = httpsCallable(functions, 'helloWorld');
  //   addMessage()
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <div className="app">
      <header>
        <div className="logo">
          <img src={Logo} alt="El Fluxometro" className="img-fluid" />
        </div>
        <div className="black-line" />
        <div className="banner">
          <img src={Banner} alt="Ahora pagar es mas facil" className="img-fluid" />
        </div>
      </header>

      <Routes />

      <Footer />
    </div>
  );
};

export default App;
