import React from 'react';
import { useTypedSelector } from '../redux';
import { Container, Card, Table } from 'react-bootstrap';
import { InvoiceDetail } from '../interfaces';
import Dinero from 'dinero.js';

const getTotal = (total: number): number => {
  const totalString = total
    .toFixed(2)
    .toString()
    .split('.')
    .join('');
  return Dinero({ amount: Number(totalString), precision: 2 }).toUnit();
};

export default () => {
  const invoice = useTypedSelector(state => state.invoice);
  return (
    <Container>
      {invoice && (
        <Card className="mb-4">
          <Card.Header>
            <strong>Cliente: </strong> {invoice.nombreCliente}
            <div className="float-right">
              <strong>Cotización:</strong> {invoice.folio}
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Unidad</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {invoice.detalles.map((detalle: InvoiceDetail) => (
                  <tr key={detalle.cotizacionDetalleId}>
                    <td>{detalle.productoDescripcion}</td>
                    <td>{detalle.unidad}</td>
                    <td>{detalle.cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <div className="float-right">
              TOTAL: <strong>{getTotal(invoice.total)}</strong>
            </div>
          </Card.Footer>
        </Card>
      )}
    </Container>
  );
};
