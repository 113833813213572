import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { Invoice } from './interfaces';

export interface State {
  invoice: Invoice | null;
  token: string | null;
}

export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_TOKEN = 'ADD_TOKEN';

interface IAddInvoice {
  type: typeof ADD_INVOICE;
  payload: Invoice;
}

interface IAddToken {
  type: typeof ADD_TOKEN;
  payload: string;
}

export type ActionTypes = IAddInvoice | IAddToken;

export const addInvoice = (invoice: Invoice) => ({ type: ADD_INVOICE, payload: invoice });

const initialState: State = { invoice: null, token: null };

const reducer = (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case 'ADD_INVOICE':
      return {
        ...state,
        invoice: action.payload,
      };
    case 'ADD_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

export const useTypedSelector: TypedUseSelectorHook<State> = useSelector;

export { reducer };
