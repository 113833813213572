import React, { useEffect, useState } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { useTypedSelector } from '../redux';
import { nanoid } from 'nanoid';
import Script from 'react-load-script';
import { useToasts } from 'react-toast-notifications';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import BeatLoader from 'react-spinners/BeatLoader';

declare global {
  interface Window {
    Checkout: any;
    paymentCancel(): void;
    paymentError(error: any): void;
    paymentComplete(resultIndicator: string, sessionVersion: string): void;
  }
}

const MakePayment: React.FC = () => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [id] = useState<string>(nanoid());
  const invoice = useTypedSelector((state) => state.invoice);

  useEffect(() => {
    // set the functions to catch the responses from evo checkout
    window.paymentError = (error: any) => {
      console.log(error);
      console.log(JSON.stringify(error));
    };

    window.paymentCancel = () => {
      setIsLoading(false);
    };

    window.paymentComplete = async (resultIndicator, sessionVersion) => {
      console.log(resultIndicator);
      console.log(sessionVersion);
      console.log('payment done');
      const docRef = await addDoc(collection(db, 'pagos'), {
        resultIndicator,
        sessionVersion,
      });
      console.log('Document written with ID: ', docRef.id);
      setShowConfirmation(true);
    };
  }, [id]);

  const createPayment = async () => {
    setIsLoading(true);

    let description: string = `Pago de la factura ${invoice?.folio}`;
    // invoice?.detalles.forEach(item => {
    //   description += `${item.productoDescripcion} - ${item.cantidad} ${item.unidad} \n`;
    // });

    try {
      const functions = getFunctions();
      const evoSession = httpsCallable(functions, 'evoSession');
      const res: any = await evoSession({
        order: {
          id: id,
          amount: invoice?.total,
          currency: 'MXN',
        },
      });

      window.Checkout.configure({
        merchant: process.env.REACT_APP_MERCHANT,
        order: {
          reference: invoice?.folio,
          description,
        },
        session: {
          id: res.data.session.id,
        },
        billing: {
          address: {
            street: 'Calle Alfa 10001',
            city: 'Guadalajara',
            postcodeZip: '44950',
            stateProvince: 'Jalisco',
            country: 'MEX',
          },
        },
        customer: {
          email: '',
        },
        interaction: {
          merchant: {
            name: 'El Fluxómetro',
            address: {
              line1: 'Av. Niños Héroes 2129',
              line2: 'Guadalajara, Jalisco',
            },
            email: 'ventas@fluxometro.com',
            phone: '(33) 3616 8639',
            logo: 'https://pagos.fluxometro.com/static/media/logo.4e0ba2a3.png',
          },
          locale: 'es_MX',
          theme: 'default',
          displayControl: {
            paymentConfirmation: 'SHOW',
            billingAddress: 'MANDATORY',
            customerEmail: 'MANDATORY',
            orderSummary: 'SHOW',
            shipping: 'HIDE',
          },
        },
      });
      window.Checkout.showLightbox();
      //window.Checkout.showPaymentPage();
    } catch (error) {
      addToast('Ha habido un error intenta de nuevo', {
        appearance: 'error',
        autoDismiss: true,
      });
      setIsLoading(false);
      if (error) {
        console.log(error);
      }
    }
  };

  const handleScriptError = () => {
    addToast('Error al cargar el sistema de pagos', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const handleScriptLoad = () => setIsLoading(false);

  const handleClose = () => window.location.reload();

  return (
    <Container>
      {invoice && (
        <div>
          <Script
            url="https://evopaymentsmexico.gateway.mastercard.com/checkout/version/56/checkout.js"
            attributes={{
              'data-cancel': 'paymentCancel',
              'data-error': 'paymentError',
              'data-complete': 'paymentComplete',
            }}
            onError={handleScriptError}
            onLoad={handleScriptLoad}
          />
          <Button
            variant="primary"
            className="float-right"
            disabled={isLoading}
            onClick={createPayment}
          >
            {isLoading ? (
              <BeatLoader size={10} color={'#fff'} loading={true} />
            ) : (
              <span>Pagar Cotización</span>
            )}
          </Button>
        </div>
      )}

      <Modal show={showConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pago Exitoso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          El pago de la cotización: {invoice?.folio} ha sido procesado.
          <br /> Referencia: <strong>{id}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MakePayment;
