import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Container, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getFunctions, httpsCallable } from 'firebase/functions';
import BeatLoader from 'react-spinners/BeatLoader';

import { Invoice } from '../interfaces';
import { addInvoice } from '../redux';
import { useDispatch } from 'react-redux';

const RetrieveInvoice: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rfc, setRfc] = useState<string>('XAXX010101000');
  const [folio, setFolio] = useState<string>('');

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const getInvoice = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    const functions = getFunctions();
    const getInvoice = httpsCallable(functions, 'getInvoice');

    try {
      const res: any = await getInvoice({ folio, rfc });
      const invoice: Invoice = res.data;
      if (invoice.status === 'Vigente') {
        dispatch(addInvoice(invoice));
      } else {
        addToast(invoice.localidad, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Form onSubmit={getInvoice} className="getInvoiceForm">
        <Col>
          <Form.Group controlId="folio">
            <InputGroup className="mb-3">
              <InputGroup.Text>Folio:</InputGroup.Text>
              <Form.Control
                type="text"
                value={folio}
                onChange={(e: any) => setFolio(e.target.value)}
                placeholder="sIngresa el folio de la factura"
                disabled={isLoading}
                required
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="rfc">
            <InputGroup className="mb-3">
              <InputGroup.Text>RFC:</InputGroup.Text>
              <Form.Control
                type="text"
                value={rfc}
                onChange={(e: any) => setRfc(e.target.value)}
                placeholder="Ingresa el RFC"
                disabled={isLoading}
                required
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Button variant="danger" type="submit" disabled={isLoading}>
            {isLoading ? (
              <BeatLoader size={10} color={'#fff'} loading={true} />
            ) : (
              <span>Obtener Cotización</span>
            )}
          </Button>
        </Col>
      </Form>
    </Container>
  );
};

export default RetrieveInvoice;
