import React from 'react';
import visa from '../images/visa.png';
import mastercard from '../images/mastercard.png';
import banorte from '../images/banorte.png';
import bbva from '../images/bbva.png';
import hsbc from '../images/hsbc.png';
import santander from '../images/santander.png';
import banamex from '../images/banamex.png';

export default () => {
  return (
    <footer>
      <div className="shape" />
      <div className="content">
        <span>Paga con tarjetas de credito:</span>
        <img src={visa} alt="visa" className="card-logo" />
        <img src={mastercard} alt="mastercard" className="card-logo" />
        <span>o de debito de los siguientes bancos:</span>
        <img src={banorte} alt="banorte" className="bank-logo" />
        <img src={bbva} alt="bbva" className="bank-logo" />
        <img src={hsbc} alt="hscb" className="bank-logo" />
        <img src={santander} alt="santander" className="bank-logo" />
        <img src={banamex} alt="banamex" className="bank-logo" />
        <br />
        ** una ves realizado el pago, no olvide mandar su comprobante a su vendedor.
      </div>
    </footer>
  );
};
