import React from 'react';
import { createRoot } from 'react-dom/client'
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import './index.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { ToastProvider } from 'react-toast-notifications';
import { reducer } from './redux';

const container = document.getElementById('root');
const root = createRoot(container!);

const store = createStore(
  reducer,
  composeWithDevTools(),
  // other store enhancers if any
);

root.render(
  <Provider store={store}>
    <ToastProvider>
      <App />
    </ToastProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
