import React from 'react';
import Invoice from './Invoice';
import MakePayment from './MakePayment';
import RetrieveInvoice from './RetrieveInvoice';

const Home = () => {
  return (
    <React.Fragment>
      <RetrieveInvoice />
      <Invoice />
      <MakePayment />
    </React.Fragment>
  );
};

export default Home;
